<template>
  <div class="mainform">
    <!-- 采购8d及fa详情页 -->
    <div class="mainHeader"> {{form.title}}
      <span @click="back" class="back">返回></span>
    </div>
    <div class="form">
      <div class="header">账号信息 </div>
      <el-form class="content bascform" ref="BasicForm" :model="form" label-width="130px">
          <div class="col col4">
            <el-form-item label="单据编号">
              <span class="onlyText">
                {{form.title}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="单据类型">
              <span class="onlyText">
                {{form.type==1?'8D':'FA'}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="质量通知单编号">
              <span class="onlyText">
                {{form.qualityCode}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="供应商">
              <span class="onlyText">
                {{form.supplierName}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="公司名称">
              <span class="onlyText">
                {{form.companyName}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="物料编码">
              <span class="onlyText">
                {{form.materielCode}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="工厂">
              <span class="onlyText">
                {{form.plantName}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="物料描述">
              <span class="onlyText">
                {{form.materielName}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="PCA要求时间">
              <span class="onlyText">
                {{form.pcaTime}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="创建日期">
              <span class="onlyText">
                {{form.createDate}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="ICA要求时间">
              <span class="onlyText">
                {{form.icaTime}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="备注">
              <span class="onlyText">
                {{form.bz}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="状态">
              <span class="onlyText" :class="form.supplierType==''?'caigou_ZT':form.supplierType==null?'gongying_ZT':form.supplierType==undefined?'gongying_ZT':form.supplierType=='2'?'wancheng_ZT':''">
                {{form.supplierType==''?'待供应商确认':form.supplierType==null?'待供应商确认':form.supplierType==undefined?'待供应商确认':form.supplierType=='2'?'供应商已确认':form.supplierType=='3'?'已驳回':form.supplierType=='4'?'已结案':''}}
              </span>
            </el-form-item>
          </div>
      </el-form>
      <div class="header">问题描述 </div>
      <el-form class="content bascform" ref="descripform" :model="form" label-width="130px">
          <div class="col col4">
            <el-form-item label="问题类型">
              <span class="onlyText">
                {{form.problemType==1?'实物问题':form.problemType==2?'交付问题':form.problemType==3?'产能问题'
                :form.problemType==4?'包装问题':form.problemType==5?'其他':''
                }}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="重视度">
              <span class="onlyText">
                {{form.levelImportance==1?'极高':form.levelImportance==2?'高':form.levelImportance==3?'一般':''}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="紧急度">
              <span class="onlyText">
                {{form.emergencyDegree==1?'非常紧急':form.emergencyDegree==2?'紧急':form.emergencyDegree==3?'一般':''}}
              </span>
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="问题详述">
              {{form.detailedProblem}}
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="附件">
              <el-upload
                :action="actionURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="handleSuccess"
                multiple
                :on-exceed="handleExceed"
                :file-list="form.fileList"
              >
              <div style="display:inline"></div>
              </el-upload>
            </el-form-item>
          </div>
      </el-form>
      <div class="header" v-if="form.status !==1">整改措施 </div>
      <el-form v-if="form.status !==1" class="content bascform" ref="form" :model="form" label-width="130px">
          <div class="col col4">
            <el-form-item label="时间">
              <span class="onlyText">
                {{rectify.rectifyTime}}
              </span>
              <!-- <input class='ZG_input' type="text" v-model="rectify.rectifyTime"> -->
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="公司">
              <span class="onlyText">
                {{rectify.rectifyCompany}}
              </span>
              <!-- <input class='ZG_input' type="text" v-model="rectify.rectifyCompany"> -->
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="部门">
              <span class="onlyText">
                {{rectify.rectifyDept}}
              </span>
              <!-- <input class='ZG_input' type="text" v-model="rectify.rectifyDept"> -->
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="备注">
              {{rectify.rectifyBz}}
              <!-- <input class='ZG_input' type="text" v-model="rectify.rectifyBz"> -->
            </el-form-item>
          </div>
          <div class="col col4">
            <el-form-item label="文件">
              <!-- <el-button type="text" v-model="form.rectifyFileList" size="mini" plain>{{rectifyFileList.name}}</el-button> -->
              <!-- <el-button size="mini" plain>点击下载附件</el-button> -->
              <el-upload
                :action="actionURL"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :headers="tokenHeader"
                :on-success="handleSuccess"
                multiple
                :on-exceed="handleExceed"
                :file-list="file1"
              >
              <div style="display:inline"></div>
              </el-upload>
            </el-form-item>
          </div>
      </el-form>
      <!--  -->
      <div class="action" v-if="form.supplierType==2" >
        <el-button class="submit" type="primary" size="medium" @click="reject">驳回</el-button>
        <!-- <el-button class="submit" type="primary" size="medium" @click="affirmBtn">确认</el-button> -->
        <el-button class="submit" type="primary" size="medium" @click="closure">结案</el-button>
      </div>
    </div>
        <!-- <dialog-box  ref="dialogbox" dialogWidth="60%" :dialogShow="dialogShow" @handleClose='handleClose'
      title="反馈整改措施" componentName="BackDialog" @affirm='affirm'
      :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{ label: '确认', action: 'affirm', size: 'mini' }]">
    </dialog-box> -->
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { request, baseURL } from '@/assets/js/http.js'
import { baseHost } from '@/assets/js/PublicData.js'
// import { baseURL } from '@/assets/js/http.js'
export default {
  components: Component.components,
  name: 'ExceptionManageDetail',
  props: [],
  data: function () {
    return {
      form: { file: [] },
      rectifyFileList: [],
      fileList: [],
      rectify: {},
      file1: [],
      tokenHeader: {
        token: localStorage.getItem('token')
      },
      actionURL: baseHost.SupplierRegHost + '/api/file/m/file/uploadToServer',
      dialogShow: false
    }
  },
  mounted () {
    const id = this.$route.query.id
    request('/api/quality/8dfa/getById/' + id, 'get').then((res) => {
      console.log(res)
      if (res.code === '200') {
        this.form = res.data
        if (this.form.rectify) {
          this.rectify = this.form.rectify
          this.file1 = this.form.rectify.rectifyFileList
        }
        console.log(this.form)
      }
    })
  },
  methods: {
    // 点击上传的文件回调
    handlePreview (file) {
      window.open(baseURL + '/api/file/m/file/downloadFile/' + file.id)
      // exportForm('/api/file/m/file/downloadFile/' + file.id, 'get').then(
      //   (response) => {
      //     var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
      //     var mime = 'application/octet-stream'
      //     if (MIME[fileExtension]) {
      //       mime = MIME[fileExtension]
      //     }
      //     const aBlob = new Blob([response.data], { type: mime })
      //     this.downloadByBlob(aBlob, file.name, mime)
      //   })
    },
    // 移除上传文件回调
    handleRemove (file) {
      for (var i = 0; i < this.file1.length; i++) {
        if (this.file1[i].id === file.id) {
          this.file1.splice(i, 1)
        }
      }
    },
    // 上传文件后的回调
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 上传成功的回调
    handleSuccess (response, file, fileList) {
      if (response.code === '200') {
        response.data.forEach(i => {
          this.file1.push(i)
        })
        console.log(this.file1)
        // const ccc = response.data
        // this.file2 = ccc
      }
    },
    handleExceed (files, fileList) {
      // this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    // preservation () {
    //   this.dialogShow = true
    // },
    affirmBtn () {
      const obj = {
        id: this.id,
        status: 3 // 已完成
      }
      console.log('obj...', obj)
      request('/api/quality/update', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            type: 'success',
            message: '确认成功'
          })
          this.$router.push('/QualitySynergy/ExceptionManage')
        }
      })
    },
    closure () {
      const obj = {
        id: this.id,
        status: 4 // 已完成
      }
      console.log('obj...', obj)
      request('/api/quality/update', 'post', obj).then((res) => {
        if (res.code === '200') {
          this.$message({
            type: 'success',
            message: '结案成功'
          })
          this.$router.push('/QualitySynergy/ExceptionManage')
        }
      })
    },
    reject () { // 驳回
      const obj = {
        ...this.form
      }
      obj.supplierType = 3
      console.log(obj)
      request('/api/quality/8dfa/save', 'post', obj).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push('/QualitySynergy/AdviseManage')
        }
      })
    },
    affirm () {
      const obj = {
        ...this.form
      }
      obj.supplierType = 4
      console.log(obj)
      request('/api/quality/8dfa/save', 'post', obj).then((response) => {
        if (response.code === '200') {
          this.$message({
            message: '成功',
            type: 'success'
          })
          this.$router.push('/QualitySynergy/AdviseManage')
        }
      })
    },
    // handleClose () {
    //   this.dialogShow = false
    // },
    // 返回
    back: () => {
      window.history.back()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/form.scss';
  @import '@/assets/css/elForm.scss';
  .mainform{
  .form {
    :deep(.el-form .col) {
        margin: 3px 0;
        .el-upload{
           display: none;
        }
      }
    }
  }
    .XG_biangeng{
    text-align: center;
  }
  .caigou_ZT{
    border-radius: 5px;
    background: #c4ca42;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    color: white !important;
    padding: 0px 5px !important;
  }
  .gongying_ZT{
    border-radius: 5px;
    background: #c81e72;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    color: white !important;
    padding: 0px 5px !important;
  }
  .wancheng_ZT{
    border-radius: 5px;
    background: #eccbc8;
    display: inline-block;
    text-align: center;
    font-size: 12px;
    color: white !important;
    padding: 0px 5px !important;
  }
</style>
